import { useMemo } from 'react'
import { timeDifference } from 'utils/helper-date'
import {
    Byline,
    Headkicker,
    Headline,
    KickerWrapper,
    NewCardContentWrapper,
    NewsCardImageWrapper,
    NewsCardWrapper,
    Teaser,
    TimeSince,
} from './NewsCard.style'

export const NewsCard = (props: ArticleProps) => {
    const {
        heading,
        headkicker,
        teaser,
        byline,
        link,
        date,
        image,
        width,
        height,
    } = props
    const timesince = useMemo(
        () => timeDifference(Date.now(), Date.parse(date)),
        [],
    )

    return (
        <NewsCardWrapper
            width={width}
            height={height}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
        >
            <NewsCardImageWrapper
                src={image}
                width={width}
                height={(width / 16) * 9}
            />
            <NewCardContentWrapper>
                <KickerWrapper>
                    <Headkicker type={headkicker}>
                        {headkicker.toUpperCase()}
                    </Headkicker>
                    <TimeSince>{timesince.toString()}</TimeSince>
                </KickerWrapper>
                <Headline>{heading}</Headline>
                <Teaser>{teaser}</Teaser>
                {byline !== 'Streamer' && <Byline>{byline}</Byline>}
            </NewCardContentWrapper>
        </NewsCardWrapper>
    )
}

export interface ArticleProps {
    heading: string
    headkicker: string
    teaser: string
    byline: string
    link: string
    date: string
    image: string
    width: number
    height: number
}
