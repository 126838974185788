import { useEffect, useState } from 'react'
import { getArticles } from 'restful-client/articles'
import { Article } from 'restful-client/types/articles'
import styled from 'styled-components'
import { NewsCard } from './NewsCard'

export interface NewsPanelProps {
    numArticles: number
    layout: 'column' | 'row'
    topic?: string
}

export const NewsPanel = (props: NewsPanelProps) => {
    const { numArticles, layout, topic } = props
    const [articles, setArticles] = useState<Article[] | null>(null)
    const width = 295
    const height = 440

    useEffect(() => {
        const fetchArticles = async () => {
            const data = await getArticles(
                `sport/${topic ?? 'streamer'}`,
                numArticles,
            )
            if (data && data.documents) {
                setArticles(data.documents)
            }
        }
        fetchArticles()
    }, [numArticles, topic])

    const renderedArticles = articles ? (
        articles.map((a) => {
            const image =
                a.mainImages.find((i) => i.ratio === '16:9') || a.mainImages[0]

            return (
                <NewsCard
                    key={a.id}
                    heading={a.homepageHead}
                    headkicker={a.headKicker}
                    teaser={a.homepageTeaser}
                    byline={a.byline.text}
                    link={a._self}
                    date={a.publicationDate}
                    image={image.src}
                    width={width}
                    height={height}
                />
            )
        })
    ) : (
        <></>
    )

    return (
        <NewsPanelWrapper row={layout === 'row'}>
            {renderedArticles}
        </NewsPanelWrapper>
    )
}

const NewsPanelWrapper = styled.div<{ row: boolean }>`
    display: flex;
    align-items: center;
    padding: 18px;
    overflow: scroll;
    ::-webkit-scrollbar {
        display: none;
    }

    width: ${(props) => (props.row ? '100%' : 'auto')};
    gap: ${(props) => (props.row ? '8px' : '18px')};
    justify-content: ${(props) => (props.row ? 'left' : 'center')};
    flex-direction: ${(props) => (props.row ? 'row' : 'column')};
`
