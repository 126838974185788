import themeGet from '@styled-system/theme-get'
import { Image } from 'components/Image'
import styled from 'styled-components'

export const NewsCardWrapper = styled.a<{ width: number; height: number }>`
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    gap: 5px;
    min-width: ${(props) => props.width}px;
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    border-radius: 8px;
    background-color: ${themeGet(`colors.gray.800`)};

    transition: 0.2s;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    -moz-transition: 0.2s;

    &:hover {
        color: ${themeGet(
            `colors.white`,
        )}; // Prevents default 'blue link hover' behaviour
        background-color: ${themeGet(`colors.gray.850`)};
    }
`

export const NewsCardImageWrapper = styled(Image)`
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
`

export const NewCardContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 10px;
`

export const Headline = styled.b`
    transition: all 0.1s ease-in-out;
    font-size: 1.5rem;
    line-height: 1.875rem;
`

export const KickerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    text-wrap: nowrap;
    font-size: 0.75rem;
`

export const Headkicker = styled.p<{ type: string }>`
    display: flex;
    align-items: center;
    height: 1.25rem;
    padding: 0px 10px;
    border-radius: 0.75rem;
    font-weight: 900;

    background-color: ${(props) =>
        props.type === 'opinion'
            ? themeGet('colors.orange.100')
            : themeGet('colors.maroon.100')};

    color: ${(props) => (props.type === 'opinion' ? '#000000' : '#FFFFFF')};
`

export const TimeSince = styled.p`
    color: ${themeGet(`colors.gray.500`)};
    font-size: 0.875rem;
`

export const Teaser = styled.div`
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.125rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
`

export const Byline = styled.p`
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: 600;
`
