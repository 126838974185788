import { get } from './helpers/request'
import { ArticleResponse } from "./types/articles"


export async function getArticles(topic: string, limit = 5) {
    try {
        const url = `https://content.thewest.com.au/v4/publication?page=1&page_size=${limit}&page_offset=0&topics=${encodeURIComponent(topic)}`
        const response = await get<ArticleResponse>(url)
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}