/* eslint-disable @typescript-eslint/no-var-requires */
import _ from 'lodash'
import { z } from 'zod'

const settings = require(`data/${process.env.NEXT_PUBLIC_SETTINGS_FILE}`)

/** temporary apply local file settings */
export default function siteSettings<T = boolean | string | number>(
    path: string,
): T {
    return _.get(settings, path, true)
}

export const SettingsSchema = z.object({
    title: z.string(),
    global: z.object({
        sticky_footer: z.boolean(),
        override_show_bhp_logo: z.boolean(),
        index_exchange_script: z.boolean(),
        moat_script: z.boolean(),
        magnite_script: z.boolean(),
    }),
    player: z.object({
        debug: z.boolean(),
        mux_analytics: z.boolean(),
        criteo_bidding: z.boolean(),
        mid_roll: z.boolean(),
        loading_spinner: z.boolean(),
        cast_buttons: z.boolean(),
    }),
    home_page: z.object({
        leagues: z.boolean(),
        gameday: z.boolean(),
        replays: z.boolean(),
        clips: z.boolean(),
        clubs: z.boolean(),
        carousel: z.boolean(),
        filter_recent_matches: z.boolean(),
        polling_interval_ms: z.number(),
        polling_window_min: z.number(),
        billboard_ad: z.boolean(),
    }),
    league_page: z.object({
        banner: z.boolean(),
        head: z.boolean(),
        leagues: z.boolean(),
        gameday: z.boolean(),
        replays: z.boolean(),
        clips: z.boolean(),
        clubs: z.boolean(),
    }),
    club_page: z.object({
        news: z.boolean(),
        clips: z.boolean(),
        teams: z.boolean(),
        banner: z.boolean(),
        follow: z.boolean(),
        gameday: z.boolean(),
        players: z.boolean(),
        replays: z.boolean(),
        sponsors: z.boolean(),
        media_gallery: z.boolean(),
    }),
    components: z.object({
        user_view_count: z.boolean(),
        follow_club_count: z.boolean(),
        follow_team_count: z.boolean(),
        fan_pass: z.boolean(),
    }),
    header_menu: z.object({
        home: z.boolean(),
        live: z.boolean(),
        admin: z.boolean(),
        teams: z.boolean(),
        players: z.boolean(),
        profile: z.boolean(),
        replays: z.boolean(),
        sign_up: z.boolean(),
        login: z.boolean(),
        manage_billing: z.boolean(),
        streamers_screamers: z.boolean(),
        get_streaming: z.boolean(),
        telethon: z.boolean(),
        voiceofwafl: z.boolean(),
        screamer: z.boolean(),
        hart: z.boolean(),
    }),
    footer_menu: z.object({}),
    replays_page: z.object({
        share: z.boolean(),
        toolbar: z.boolean(),
        carousel: z.boolean(),
        thumbnail: z.boolean(),
        commentary: z.boolean(),
        save_match: z.boolean(),
        create_clip: z.boolean(),
        become_a_fan: z.boolean(),
    }),
    game_day_page: z.object({
        cheer: z.boolean(),
        share: z.boolean(),
        toolbar: z.boolean(),
        carousel: z.boolean(),
        thumbnail: z.boolean(),
        commentary: z.boolean(),
        save_match: z.boolean(),
        create_clip: z.boolean(),
        become_a_fan: z.boolean(),
        overlay_score: z.boolean(),
        overlay_score_time: z.boolean(),
        commentary_tab: z.boolean(),
        match_stats_tab: z.boolean(),
        media_gallery_tab: z.boolean(),
        gameday_write_up: z.boolean(),
        submit_upsert_comment: z.boolean(),
    }),
    player_profile: z.object({
        bio: z.boolean(),
        clips: z.boolean(),
        follow: z.boolean(),
        profile: z.boolean(),
    }),
    profile_page: z.object({
        bio: z.boolean(),
        clubs: z.boolean(),
        teams: z.boolean(),
        players: z.boolean(),
        save_match: z.boolean(),
        reels: z.boolean(),
    }),
    team_page: z.object({
        banner: z.boolean(),
        header: z.boolean(),
        gameday: z.boolean(),
        replay: z.boolean(),
        clips: z.boolean(),
        players: z.boolean(),
    }),
    about_page: z.object({
        register_btn: z.boolean(),
    }),
    register_page: z.object({
        show_page: z.boolean(),
    }),
    event_stream_page: z.object({
        commentary: z.boolean(),
        add_event: z.boolean(),
        set_thumbnail: z.boolean(),
        set_matchtime: z.boolean(),
        download: z.boolean(),
        commentary_tab: z.boolean(),
        clips_tab: z.boolean(),
        match_stats_tab: z.boolean(),
        media_gallery_tab: z.boolean(),
        gameday_write_up_tab: z.boolean(),
        save_mobile: z.boolean(),
        scoring_mobile: z.boolean(),
        set_thumbnail_mobile: z.boolean(),
        set_matchtime_mobile: z.boolean(),
    }),
    clubs_and_leagues_page: z.object({
        show_page: z.boolean(),
        show_testimonial: z.boolean(),
        show_stats: z.boolean(),
        show_contact_form: z.boolean(),
    }),
    default_bitmovin_video_player_page: z.object({
        show_page: z.boolean(),
    }),
    temporary: z.object({
        final_pass_league_slug: z.string(),
        final_pass_price_id: z.string(),
    }),
})

export function getSiteSettings() {
    return settings as z.infer<typeof SettingsSchema>
}
