import styled from 'styled-components'
import { defaultTheme } from 'theme'
import MediaSize from 'theme/mediaSize'

export const ScreamerFormWrapper = styled.div`
    @media screen and (min-width: ${MediaSize.lg}px) {
        width: 800px;
        min-width: 700px;
        /* border-right: 1px solid #292929; */
    }
    @media screen and (max-width: ${MediaSize.lg}px) {
        width: 100%;
    }
    @media screen and (max-width: 440px) {
        width: 100%;
        max-width: 372px;
        margin: auto;
    }
`

export const ScreamerBottomWrapper = styled.div`
    @media screen and (min-width: 768px) {
        width: 100%;
        border-right: 1px solid #292929;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: row;

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        flex-direction: column-reverse;
    }
`

export const ImageContainer = styled.div`
    display: flex;
    flex: 1 1 0%;
    justify-content: center;
    align-self: center;

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        order: 1;
    }
`

export const ContentContainer = styled.article`
    display: flex;
    flex-flow: column;
    flex: 1 1 0%;
    gap: 30px;
    margin: auto;
    padding: 50px;

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        padding: 0px;
        order: 2;
        margin-bottom: 30px;
    }
`

export const ContentHeader = styled.div``

export const ContentBody = styled.div`
    display: flex;
    flex-flow: column;
    gap: 30px;
`

export const OrderedList = styled.ol`
    list-style-type: decimal;
    list-style-position: inside;
`

export const ListTag = styled.li`
    list-style-type: circle;
    list-style-position: inside;
`

export const TermsWrapper = styled.article``

export const TermsLinkWrapper = styled.a`
    color: white;
    text-decoration: underline;
`

export const LinkWrapper = styled.a`
    color: white;
    text-decoration: underline;
    margin-left: 5px;
`

export const SubmitClipWrapper = styled.div``
