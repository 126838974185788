import TopBannerUltraWide from 'assets/images/screamer/2025_HART_ultrawide.jpg'
import TopBannerWide from 'assets/images/screamer/2025_HART_wide.jpg'
import { BlockContainer } from 'components/Container'
import AdsGPT from 'components/GPT/AdsGPT'
import { Image } from 'components/Image'
import { NewsPanel } from 'components/NewsPanel'
import { Page } from 'components/Page'
import { getSiteSettings } from 'hooks/settings'
import useMediaQuery from 'hooks/useMediaQuery'
import type { GetServerSideProps } from 'next'
import Script from 'next/script'
import { ScreamerFormWrapper } from 'sections/screamers/common.style'
import MediaSize from 'theme/mediaSize'
import { baseUrl } from 'utils/constData'
import { isAfter } from 'utils/helper-date'

export function showHartPage() {
    return isAfter('2025-03-19T05:00:00.000+08:00')
}

const HartPage = () => {
    const isWide = !useMediaQuery(MediaSize.lg)
    const isMobile = useMediaQuery(768)

    const title = 'Hart Of Your Club'
    const canonical = `${baseUrl}/hart-of-your-club`
    const description =
        'Want to shine a light on your sporting club hero? Tell us who the HART of your club is to possibly walk away with $5k worth of sporting goods thanks to our friends at HART Sport.'
    const socialImage = `${process.env.NEXT_PUBLIC_ASSETS_BUCKET_CDN_URL}/screamer/2025-HART-social.jpg`
    const bannerImage = isWide ? TopBannerUltraWide : TopBannerWide

    return (
        <Page
            title={title}
            description={description}
            type="website"
            image={socialImage}
            canonical={canonical}
        >
            <BlockContainer padding={0} smMargin={0} smPadding={0} margin={0}>
                <Image
                    alt="Hart Of Your Club. Win $5K In Sports Goods."
                    src={bannerImage}
                    mode="responsive"
                    quality={90}
                />
            </BlockContainer>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flexDirection: isWide ? 'row' : 'column',
                }}
            >
                <ScreamerFormWrapper id="form_wrapper">
                    <Script id="campaign-embed">
                        {`!function(){var e=document.getElementById("campaign-embed-code");e||((e=document.createElement("SCRIPT")).src="https://d1m2uzvk8r2fcn.cloudfront.net/scripts/embed-code/20230927/embed.min.js",e.id="campaign-embed-code",document.body.appendChild(e))}()`}
                    </Script>
                    <iframe
                        allow="autoplay"
                        sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-popups-to-escape-sandbox"
                        id="631n0K"
                        className="embed_frame"
                        loading="lazy"
                        src="https://m.cmpgn.page/631n0K?embed=3&script=1"
                        style={{
                            border: 'none',
                            minWidth: '100%',
                            height: '2470px',
                        }}
                        allowFullScreen
                        data-hj-allow-iframe
                    ></iframe>
                </ScreamerFormWrapper>
                {isWide && <NewsPanel numArticles={4} layout="column" />}
            </div>

            {!isWide && <NewsPanel numArticles={4} layout="row" />}

            {!isMobile && (
                <div style={{ marginTop: 50 }}>
                    <AdsGPT slot={`desktop-hart-footer`} pos={2} />
                </div>
            )}
        </Page>
    )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    const showPage = getSiteSettings().header_menu.hart && showHartPage()

    return showPage
        ? {
              props: {},
          }
        : {
              redirect: {
                  destination: '/',
                  permanent: false,
              },
          }
}

export default HartPage
